<template>
  <div>
    <div class="form--group form--group-switch">
      <div class="left--col">
        <span>{{
          listingType === 'R' ? $t('addListing.occupied.rent') : $t('addListing.occupied.sell')
        }}</span>
        <img src="@/assets/img/icons/info.svg" class="info--icon" id="occupied-section" />
        <PopOverListingForm
          target="occupied-section"
          :content="
            listingType === 'R'
              ? $t('addListing.hint.occupied.rent')
              : $t('addListing.hint.occupied.sell')
          "
        />
      </div>
      <div class="right--col">
        <button
          type="button"
          @click="occupied = true"
          class="btn px-4"
          :class="{ selected: occupied }"
        >
          {{ $t('general.yes') }}
        </button>
        <button
          type="button"
          class="btn px-4"
          @click="occupied = false"
          :class="{ selected: !occupied }"
        >
          <span style="position: relative;">{{ $t('general.no') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PopOverListingForm from '@/components/listing-form/pop-over-listing-form';

export default {
  name: 'view-to-offer-switch',
  data() {
    return {};
  },
  components: {
    PopOverListingForm,
  },
  computed: {
    ...mapState({
      listingType: state => state.v2.listingForm.listingType,
    }),

    occupied: {
      get() {
        return this.$store.state.v2.listingForm.sect1.basicInfo.occupied;
      },
      set(value) {
        this.$store.commit('v2/listingForm/sect1/basicInfo/SET_OCCUPIED', value);
      },
    },
  },

  methods: {},
};
</script>

<style scoped></style>
